import * as React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PaymentIcons from '../../../molecules/PaymentIcons'
import { Gq } from './types'
import Copyright from './Copyright'
import theme from 'theme'
import useTranslations from 'hooks/useTranslations'

export default function CheckoutFooter() {
  const t = useTranslations<'app-Footer'>()
  const gq: Gq = useStaticQuery(graphql`
    query appCheckoutFooter {
      footer {
        aboutLinks {
          urlPath
          title
        }
        serviceLinks {
          urlPath
          title
        }
        facebook
        youtube
        instagram
        whatsapp
        pinterest
        linkedin
        xing
        legal {
          title
          urlPath
        }
        catalogImage
        catalogLink
        newsletter {
          title
          iconSource
          disclaimer
          labels
        }
        advantages {
          label
          link
        }
        contactInfo {
          title
          value
          info
          type
        }
        paymentMethods {
          url
          key
        }
        trustIcon
        trustLink
      }
      brandName: category(objectID: { eq: "Marken" }) {
        path
      }
    }
  `)
  const { footer } = gq
  return (
    <Wrapper data-cy-ctx="app/Footer">
      {footer.paymentMethods && (
        <PaymentIcons
          headline={true}
          data-cy-state="available-payments-visible"
          iconList={footer.paymentMethods}
        />
      )}
      <Copyright footer={footer} />
      <div className="bottom-line">{t('footer_customer_trust_quote')}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .bottom-line {
    display: flex;
    height: 44px;
    color: ${theme.colors.white};
    background: ${theme.colors.b0};
    ${theme._ty([13, 0, 16], theme.font, '400')}
    justify-content: center;
    align-items: center;

    @media (min-width: ${theme.ms.MD}px) {
      ${theme._ty([16, 0, 24], theme.font, '400')}
    }
  }
`

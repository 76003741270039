import * as React from 'react'
import styled, { css } from 'styled-components'
import useTranslations from 'hooks/useTranslations'
import theme from 'theme'
import { ResponseType } from '../useNewsletterSubmit'

type Props = {
  handleSubmit: (email: string, e) => void
  response: ResponseType | null
  hasPromotionIcon: boolean
  isSubmitting: boolean
}

export default function NewsletterForm(props: Props) {
  const t = useTranslations<'app-Footer'>()
  const [email, setEmail] = React.useState('')
  const $input = React.useRef<any>(null)

  const hideForm =
    props.response === 'success' || props.response === 'success_no_mail'

  if (hideForm) return null

  return (
    <Wrapper
      className="NewsletterForm"
      onSubmit={(e) => props.handleSubmit(email, e)}
      $hasPromoIcon={props.hasPromotionIcon}
      $isSubmitting={props.isSubmitting}
    >
      <span className="inputfield">
        <input
          id="newsletter"
          ref={$input}
          title={t.asText($input, 'app_footer_newsletter_input_a11y_label')}
          placeholder={t.asText($input, 'newsletter_input_placeholder')}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
        <button type="submit">{t('newsletter_btn_label')}</button>
      </span>
    </Wrapper>
  )
}

const Wrapper = styled.form<{
  $hasPromoIcon: boolean
  $isSubmitting: boolean
}>`
  display: block;
  .inputfield {
    display: flex;
    min-height: 2.5rem;
    position: relative;
    @media (min-width: ${theme.ms.MD}px) {
      min-width: 23.75rem;
      ${(props) =>
        !props.$hasPromoIcon &&
        css`
          justify-content: center;
        `}
    }
    ${theme.ty('rc-2xl')}
    margin-bottom: ${theme.spacing('s')};

    > input {
      ${theme.ty('r-s', null, 'r-base')}

      flex-grow: 1;
      color: ${theme.colors.shade.b2};
      height: 2.3125rem;
      box-sizing: border-box;
      padding: ${theme.spacing('xs')} ${theme.spacing('m')};
      border-radius: 5px;
      outline: none;
      border: none;
      -webkit-appearance: none;
    }
    > button {
      background: ${theme.colors.b0};
      color: ${theme.colors.white};
      ${theme.rounding('m')};
      ${theme.ty('rc-base', 'bold')}
      box-sizing: border-box;
      width: inherit;
      position: absolute;
      right: 1px;
      top: 1px;
      border: 1px solid ${theme.colors.white};
      border-radius: 0px 5px 5px 0px;
      height: 2.1875rem;
      padding: 0.4375rem 1.5625rem;

      ${(p) =>
        p.$isSubmitting &&
        css`
          cursor: progress;
        `}
    }
  }
`

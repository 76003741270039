import { addRule } from 'redux-ruleset'
import { push as dlPush } from 'features/tracking/datalayer'

addRule({
  id: 'dl/checkpout-error',
  target: 'SHOPWARE_ERROR',
  output: '#dl-event',
  condition: () => window.location.href.includes('checkout'),
  consequence: (action) => {
    const isCheckout = window.location.href.includes('checkout')

    if (isCheckout) {
      // quick fix --> TODO: refactor
      if (action.payload.error) {
        const error = action.payload.error.reduce((acc, curr) => {
          acc += curr.pointer + ':' + curr.code + '|'
          return acc
        }, '')
        dlPush({
          event: 'genericEvent',
          eventname: 'error_message',
          category: 'checkout',
          action: 'error_message',
          label: error.slice(0, -1),
          value: 0,
          nonInteraction: 'false'
        })
      }
    }
  }
})

addRule({
  id: 'dl/ShopwareServerError',
  target: 'SHOPWARE_SERVER_ERROR',
  output: '#dl-event',
  consequence: (action) => {
    dlPush({
      event: 'genericEvent',
      eventname: 'error_message',
      category: 'checkout',
      action: 'error_message',
      label: action.payload.error,
      value: 0,
      nonInteraction: 'false'
    })
  }
})

import * as React from 'react'
import { dispatchEvent } from 'redux-ruleset'
import usePageContext from 'hooks/usePageContext'
import { FetchContentResult } from 'utils/dy-hybrid/endpoints/fetchContent'
import { fetchContent } from 'utils/dy-hybrid'
import { PersonalizationProvider } from 'hooks/usePersonalization'
import useInView from 'hooks/useInView'
import { isValid, Shape } from 'utils/shape'

const cache: Record<string, any> = {}

export type Props<Type, DYResponse> = {
  selector: string
  pageType?: string
  /**
   * **NO_CACHE**: a loading spinner will be displayed while data is fetched. data will always be refetched when Components
   * mounts. Data will not be stored. If fetch yields content the render method will be displayed with dy-content
   *
   * **BLUR**: when we have cached data we display it. otherwise we display the initialData and add a blur effect on it.
   * when Atoms mounts we fetch content and save it to cache and render it with the new data (without blur effect)
   */
  strategy: 'NO_CACHE' | 'BLUR'
  preventDySeenEvent?: boolean
  initialData?: Type | null
  preventClickTracking?: boolean
  loading?: React.JSX.Element
  shape: Shape
  autoTrack?: boolean
  showDefaultDataByControlGroup?: boolean
  processResponse?: (
    raw: FetchContentResult<DYResponse>
  ) => Promise<Type> | Type
  render: (data: Type) => React.JSX.Element
}

type TrackingConfig = {
  decisionId: string
  campaignId: string
  campaignName: string
  experienceId: string
  experienceName: string
  variationId: string
  variationName: string
}

export const init = (config: TrackingConfig) => {
  if (config.decisionId)
    return dispatchEvent({
      type: 'Personalization/INIT' as const,
      payload: config
    })
  return
}

export const click = (config: TrackingConfig) =>
  dispatchEvent({
    type: 'Personalization/CLICK' as const,
    payload: config
  })

export const scrollIntoView = (
  selector: string,
  preventDySeenEvent?: boolean
) =>
  dispatchEvent({
    type: 'Personalization/SCROLL_INTO_VIEW' as const,
    payload: { selector, preventDySeenEvent }
  })

declare global {
  interface RulesetDispatchEvents {
    'atoms/Personalization':
      | ReturnType<typeof init>
      | ReturnType<typeof click>
      | ReturnType<typeof scrollIntoView>
  }
  interface Window {
    CONFIG_TYPE?: string
  }
}

export default function Personalization<Type, DYResponse>(
  props: Props<Type, DYResponse>
) {
  const [data, setData] = React.useState<Type | null>(
    cache[props.selector] || props.initialData || null
  )
  const [showDefault, setShowDefault] = React.useState(false)
  const [trackingConfig, setTrackingConfig] =
    React.useState<TrackingConfig | null>(null)
  const [invalid, setInvalid] = React.useState(false)
  const [loaded, setLoaded] = React.useState(!!cache[props.selector])
  const pageContext = usePageContext()
  const [ref, wasSeen] = useInView<HTMLDivElement>(0, true, 0.3, loaded)

  const trackClick = () => {
    if (props.preventClickTracking) return
    if (!trackingConfig) return
    click(trackingConfig)
  }

  /** send event when user has seen personalized content */
  React.useEffect(() => {
    if (!wasSeen) return
    if (invalid) return

    scrollIntoView(props.selector, props.preventDySeenEvent)
  }, [wasSeen, invalid])

  React.useEffect(() => {
    const path =
      pageContext?.type === 'Category' ? pageContext.dyPath : undefined
    if (window.isStorybook && window.CONFIG_TYPE !== 'DEVELOPMENT') {
      setShowDefault(true)
      return
    }
    // we need to wait for page-ready before we can fetch
    if (pageContext && !pageContext.ready) return

    fetchContent<DYResponse>({
      selector: props.selector,
      path: path, // can be deleted when old implementation is removed
      pageContext: pageContext
    })
      .then(async (result) => {
        if (
          typeof result.context === 'undefined' ||
          !result.context.decisionId
        ) {
          if (props.showDefaultDataByControlGroup) {
            setShowDefault(true)
          }
          setInvalid(true)
          return
        }

        setTrackingConfig(result.context)
        if (!isValid(props.shape, result.content)) {
          setInvalid(true)
          setData(null)
          //wenn invalid, dann soll der content von DY nicht angezeigt werden und der default content soll angezeigt werden wenn decisionId vorhanden ist
          if (props.showDefaultDataByControlGroup) {
            setShowDefault(true)
          }
          return
        } else {
          const transformed = props.processResponse
            ? await props.processResponse(result as any)
            : (result as any)

          setData(transformed)
          setLoaded(true)

          if (props.strategy === 'BLUR') {
            cache[props.selector] = transformed
          }
        }

        init(result.context)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
        setInvalid(true)
      })
  }, [
    props.selector,
    pageContext?.ready,
    props.showDefaultDataByControlGroup
    // account.cart.data.dy.userID,
    // account.cart.data.dy.sessionID,
    // account.cart.setup
  ])

  if (props.initialData && showDefault) {
    return (
      /* eslint-disable @kaminrunde/firescout/onclick-needs-handle*/
      <PersonalizationProvider
        selector={props.selector}
        trackClick={trackClick}
        decisionId={trackingConfig?.decisionId || ''}
      >
        <div onClick={props.autoTrack ? trackClick : undefined}>
          {props.render(props.initialData)}
        </div>
      </PersonalizationProvider>
    )
  }
  if (invalid) return null

  if (!data) return props.loading || null

  if (props.strategy === 'BLUR' && !loaded) {
    return <div style={{ filter: 'blur(30px)' }}>{props.render(data)}</div>
  }

  return (
    /* eslint-disable @kaminrunde/firescout/onclick-needs-handle*/
    <PersonalizationProvider
      selector={props.selector}
      trackClick={trackClick}
      decisionId={trackingConfig?.decisionId || ''}
    >
      <div ref={ref} onClick={props.autoTrack ? trackClick : undefined}>
        {props.render(data)}
      </div>
    </PersonalizationProvider>
  )
}

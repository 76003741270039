import store from 'store'
import * as a from './actions'
import { addRule, dispatchEvent } from 'redux-ruleset'
import * as at from './const'
import { LOCATION_CHANGED } from 'modules/navigation/const'

declare global {
  interface Window {
    __module_ui_opted_in: () => void
  }
}

if (typeof window !== 'undefined') {
  window.__module_ui_opted_in = function () {
    store.dispatch(a.setOptIn())
  }
}

// https://jira.em-group.de/browse/WDV-3602
addRule({
  id: 'dl/NEWSLETTER_USER_IDENTIFY',
  target: LOCATION_CHANGED,
  output: '#dl-event',
  condition: (action) => {
    if (!action.payload.href.search('&sc_uid=')) return false
    if (!action.payload.href.search('&utm_medium=')) return false
    const sc_uidMatch = action.payload.href.match(/&sc_uid=([^&]+)/)
    const utmMatch = action.payload.href.match(/&utm_medium=([^&]+)/)
    if (!sc_uidMatch || !utmMatch) return false
    if (utmMatch[1].toLowerCase() === 'servicemails_transaktionsmail')
      return false
    return true
  },
  consequence: () => {
    localStorage.setItem('intentLayerExecuted', 'true')
    store.dispatch({ type: at.REGISTER_NEWSLETTER_NL })
  }
})
//WDV-3313 NewsletterIntentLayer
// show intent layer after 5 pageviews
// set localStorage item to prevent showing the intent layer again
addRule({
  id: 'ui/IntentLayer',
  target: at.INCREMENT_LOCATION_CHANGE_COUNTER,
  output: at.SET_INTENT_VISIBLE,
  condition: (_, { getState }) => {
    const state = getState()
    const counter = state.ui.locationChangeCounter
    const isExecuted = localStorage.getItem('intentLayerExecuted') || false
    const isFirstPage =
      sessionStorage.getItem('intentLayerFirstpage') ===
      localStorage.getItem('intentLayerFirstpage')

    return (
      counter > 5 &&
      !state.ui.registerNewsletterNL &&
      !isExecuted &&
      isFirstPage
    )
  },
  delay: 100,
  addOnce: true,
  consequence: () => {
    store.dispatch({
      type: at.SET_INTENT_VISIBLE,
      payload: { visible: true, source: '5_pageviews' }
    })
  }
})

export const getCategoryPath = (categories) =>
  categories.lvl7?.[0].split('>').join('/') ||
  categories.lvl6?.[0].split('>').join('/') ||
  categories.lvl5?.[0].split('>').join('/') ||
  categories.lvl4?.[0].split('>').join('/') ||
  categories.lvl3?.[0].split('>').join('/') ||
  categories.lvl2?.[0].split('>').join('/') ||
  categories.lvl1?.[0].split('>').join('/') ||
  categories.lvl0?.[0].split('>').join('/') ||
  ''
export default getCategoryPath

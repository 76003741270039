import config from '../../src/config'
import { PlainSearchParameters } from 'algoliasearch-helper'
import dlManager from './dy-hybrid/manager'
import { algoliasearch } from 'algoliasearch'
import algoliasearchHelper from 'algoliasearch-helper'

/** @firescoutMockFn algolia.createAlgoliaHelper */
export default async function createAlgoliaHelper(
  index: string,
  options: PlainSearchParameters
) {
  const userToken =
    typeof window === 'undefined'
      ? { _dyid_server: 'server', sessionId: 'server' }
      : dlManager._unsaveGetContext()._dyid_server || 'unknown'
  const client = algoliasearch(
    config.modules.algolia.applicationId,
    config.modules.algolia.apiKey,
    {
      // @ts-ignore: ignore TypeScript error in next line
      headers: {
        'X-Algolia-UserToken': userToken as string
      }
    }
  )
  // @ts-ignore: ignore TypeScript error in next line
  const helper = algoliasearchHelper(client, index, {
    attributesToHighlight: [],
    ...options
  })

  /**
   * We want to distinguish between server and client request and bots to track where our
   * queries come from so we add a analytics tag in our request
   */
  let isBot = false
  if (typeof window !== 'undefined')
    isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
      navigator.userAgent
    )
  const isFrontend = typeof window !== 'undefined'
  const tags: string[] = []
  if (isBot) tags.push('frontend-bot')
  if (!isBot && isFrontend) tags.push('frontend-client')
  if (!isFrontend) tags.push('frontend-server')

  if (isFrontend && window.location.pathname.includes('/pdp/')) {
    tags.push('pdp')
  }

  helper.setQueryParameter('analyticsTags', tags)

  // monkeypatch "setQueryParameter"
  helper.setQueryParameter = (name, value) => {
    const proto = Object.getPrototypeOf(helper)
    if (name !== 'analyticsTags')
      return proto.setQueryParameter.bind(helper)(name, value)
    return proto.setQueryParameter.bind(helper)(name, [
      ...(value as any),
      ...tags
    ] as any)
  }

  return helper
}

import transform from 'slugify'
import config from '../config'
import { SlugifyLocale } from 'config/config.type'

function putSlashAtEnd(s: string) {
  if (s[s.length - 1] !== '/') {
    return s + '/'
  }
  return s
}

export default (
  s: string,
  slugifyLocale: SlugifyLocale = config.slugifyLocale
): string =>
  s
    .split(' > ')
    .map((s) =>
      transform(s, {
        replacement: '-', // replace spaces with replacement character, defaults to `-`
        remove: undefined, // remove characters that match regex, defaults to `undefined`
        lower: true, // convert to lower case, defaults to `false`
        strict: false, // strip special characters except replacement, defaults to `false`
        locale: slugifyLocale // language code of the locale to use
      })
    )
    .join('/') + '/'

export const slugifyWithSlashes = (
  s: string,
  slugifyLocale: SlugifyLocale = config.slugifyLocale
) =>
  s
    .split('/')
    .map((s) =>
      transform(s, {
        replacement: '-', // replace spaces with replacement character, defaults to `-`
        remove: undefined, // remove characters that match regex, defaults to `undefined`
        lower: true, // convert to lower case, defaults to `false`
        strict: false, // strip special characters except replacement, defaults to `false`
        locale: slugifyLocale // language code of the locale to use
      })
    )
    .join('/')

export const slugifyForSeries = (
  s: string,
  slugifyLocale: SlugifyLocale = config.slugifyLocale
) => {
  const specialChars = [
    '&',
    '@',
    '#',
    '$',
    '%',
    '^',
    '*',
    '(',
    ')',
    '+',
    '=',
    '{',
    '}',
    '[',
    ']',
    '|',
    '\\',
    ';',
    '"',
    "'",
    '<',
    '>',
    ',',
    '?'
  ]
  const placeholderMap: { [key: string]: string } = {}
  specialChars.forEach((char, index) => {
    placeholderMap[char] = `__placeholder_${index}__`
  })

  let url = s
  Object.keys(placeholderMap).forEach((char) => {
    url = url.replaceAll(char, placeholderMap[char])
  })
  const slugifiedUrl = slugifyWithSlashes(url, 'de')
  let slugifiedUrlConverted = slugifiedUrl
  Object.keys(placeholderMap).forEach((char) => {
    slugifiedUrlConverted = slugifiedUrlConverted.replaceAll(
      placeholderMap[char],
      char
    )
  })

  return putSlashAtEnd(slugifiedUrlConverted)
}

import config from 'config'
import * as React from 'react'
import * as t from './types'
import { CreateOrderResponse } from './response-types/create-order'
import { HandlePaymentResponse } from './response-types/handle-payment'
import { Product } from 'theme/molecules/Flags/types'
import { Variant } from 'modules/productDetail/types'
import { logError } from 'utils/buzdev'
import { getChannel } from 'utils/channel'
import { dispatchEvent } from 'redux-ruleset'

const tokenKey = `${config.locale.split('-').pop()}:sw6-token`

let token =
  typeof window !== 'undefined' ? localStorage.getItem(tokenKey) || '' : ''

// Old token Migration logic
// If we have a token for shopware, that is not assigned to a country, we assign it to the current country.
// The old token will be deleted.
// TODO: Remove after a few months
if (typeof window !== 'undefined') {
  const oldToken = localStorage.getItem('sw6-token')
  if (oldToken) {
    localStorage.removeItem('sw6-token')
    localStorage.setItem(tokenKey, oldToken)
    token = oldToken
  }
}

export const updateToken = (newToken: string) => {
  if (newToken === '') {
    localStorage.removeItem(tokenKey)
  }
  if (newToken !== token) {
    localStorage.setItem(tokenKey, newToken)
    token = newToken
  }
}

export const getToken = () => token

let fetchCount = 0
if (process.env.NODE_ENV === 'development') {
  setInterval(() => {
    if (fetchCount > 0) fetchCount--
  }, 100)
}

/**
 * returns a boolean if the item is restricted for the given channel or not
 */
export const isItemChannelRestricted = (
  item: t.CartItem | Product | Variant,
  channel: 'b2b' | 'b2c'
): boolean => {
  return (
    (channel === 'b2b' && item.channelActive?.b2b === false) ||
    (channel === 'b2c' && item.channelActive?.b2c === false)
  )
}

export const fetchFromShopware = async <Result = any>(options: {
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH'
  channel?: 'b2b' | 'b2c'
  url: string
  body?: Record<string, unknown>
  headerOptions?: Record<string, string> | Record<string, never | undefined>
}): Promise<[Result, number, string]> => {
  fetchCount++
  if (process.env.NODE_ENV === 'development') {
    if (fetchCount > 100) {
      alert('fetchFromShopware: too many calls!')
      throw new Error('fetchFromShopware: too many calls!')
    }
  }

  const channel = options.channel ?? getChannel()

  const headers = {
    'Content-Type': 'application/json',
    'sw-access-key': config.modules.shopware.accessKey[channel]
  }
  if (options.headerOptions) {
    Object.keys(options.headerOptions).forEach((key) => {
      headers[key] = options.headerOptions?.[key] || ''
    })
  }
  if (token) headers['sw-context-token'] = token
  const fetchOptions: any = {
    headers: headers,
    method: options.method
  }
  if (options.body) fetchOptions.body = JSON.stringify(options.body)
  return shopwareFetch({
    url: options.url,
    options: fetchOptions
  })
}

/** @firescoutMockFn shopware.api-call */
function shopwareFetch(
  props: {
    url: string
    options: {
      headers: Record<string, string>
      method: 'GET' | 'POST' | 'DELETE' | 'PATCH'
      body: any
    }
  },
  retry = true
): Promise<[any, number, string]> {
  return fetch(config.modules.shopware.apiUrl + props.url, props.options).then(
    async (res) => {
      const token = res.headers.get('sw-context-token') || ''
      if (token) updateToken(token)
      if (res.status === 204) return [null, 204, token]
      const result = await res.json()
      if (res.status >= 400) {
        dispatchEvent({
          type: 'SHOPWARE_ERROR',
          payload: result.errors || result
        })
        logError({
          type: 'fetch-from-shopware',
          msg: 'xhr to shopware failed',
          stack: '-',
          loc: window.location.href,
          ua: navigator.userAgent,
          xhr: {
            url: props.url,
            method: props.options.method,
            status: res.status,
            result: JSON.stringify(result)
          }
        })
      }

      return [result, res.status, token]
    },
    /** retry one more time after 100ms */
    async (error) => {
      if (!retry) {
        dispatchEvent({
          type: 'SHOPWARE_ERROR',
          payload: { status: 500, result: error }
        })
        logError({
          type: 'fetch-from-shopware',
          msg: error.toString(),
          stack: error.stack ?? '',
          loc: window.location.href,
          ua: navigator.userAgent,
          xhr: {
            url: props.url,
            method: props.options.method,
            status: 500,
            result: JSON.stringify({
              error: error.toString(),
              stack: error.stack
            })
          }
        })
        return Promise.reject(error) as any
      }
      await new Promise((r) => setTimeout(r, 100))
      return shopwareFetch(props, false)
    }
  )
}

export function createAddress(sw6Address: any): t.Address {
  const initial = (s?: string) => (s === 'INITIAL' ? '' : s || '')
  const address: t.Address = {
    id: initial(sw6Address?.id),
    city: initial(sw6Address?.city),
    company: initial(sw6Address?.company),
    countryId: initial(sw6Address?.countryId),
    firstName: initial(sw6Address?.firstName),
    lastName: initial(sw6Address?.lastName),
    phoneNumber: initial(sw6Address?.phoneNumber),
    salutationId: initial(sw6Address?.salutationId),
    street: initial(sw6Address?.street),
    zipcode: initial(sw6Address?.zipcode),
    additionalAddressLine1: initial(sw6Address?.additionalAddressLine1),
    countryStateId: initial(sw6Address?.countryStateId),
    isIncomplete: false,
    customFields: {
      lusiniHouseNumber: initial(sw6Address?.customFields.lusiniHouseNumber),
      lusiniIdentificationCode: initial(
        sw6Address?.customFields.lusiniIdentificationCode
      ),
      lusiniVatId: initial(sw6Address?.customFields.lusiniVatId),
      lusiniChamberOfCommerceNumber: initial(
        sw6Address?.customFields.lusiniChamberOfCommerceNumber
      ),
      lusiniPECEmail: initial(sw6Address?.customFields.lusiniPECEmail),
      lusiniGLN: initial(sw6Address?.customFields.lusiniGLN),
      lusiniReferenceNumber: initial(
        sw6Address?.customFields.lusiniReferenceNumber
      )
    }
  }

  const initialKeys = [
    'firstName',
    'lastName',
    'city',
    'phoneNumber',
    'street',
    'zipcode'
  ]

  for (const key of initialKeys) {
    if (address[key] === 'INITIAL') {
      address[key] = ''
      address.isIncomplete = true
    }
  }

  for (const key in address.customFields) {
    const value = address.customFields[key] as string
    if (value === 'INITIAL') {
      address.customFields[key] = ''
      address.isIncomplete = true
    }
  }

  return address
}

export function useForm<
  T extends Record<string, { value: unknown; error: null | string }>,
  R
>(
  initialForm: T,
  onSubmit: (
    data: T,
    setErrors: (mapping: Record<string, string>, errors: t.UserError[]) => void,
    setGlobalError: () => void
  ) => Promise<R>
) {
  const [form, setForm] = React.useState(initialForm)
  const [submitting, setSubmitting] = React.useState(false)
  const [globalError, setGlobalError] = React.useState(false)
  const formRef = React.useRef(form)

  const set = <K extends keyof T>(key: K, value: T[K]['value']) => {
    formRef.current = {
      ...formRef.current,
      [key]: {
        value: value,
        error: null
      }
    }
    setForm(formRef.current)
  }

  const setError = <K extends keyof T>(key: K, value: string | undefined) => {
    formRef.current = {
      ...formRef.current,
      [key]: {
        value: formRef.current[key].value,
        error: value
      }
    }
    setForm(formRef.current)
  }

  const submit = async () => {
    if (globalError) setGlobalError(false)
    setSubmitting(true)
    const result = await onSubmit(
      formRef.current,
      (mapping, errors) => {
        for (const error of errors) {
          const key = mapping[error.pointer]
          if (!key) {
            if (process.env.NODE_ENV === 'development') {
              alert(`unknown error key "${error.pointer}"`)
            }
            setGlobalError(true)
          } else setError(key, error.code)
        }
      },
      () => setGlobalError(true)
    )
    setSubmitting(false)
    return result
  }

  return {
    data: form,
    submitting,
    globalError,
    set,
    submit
  }
}

export async function createOrder(
  paypalOrderId = '',
  outsideSalesRepData: t.OutsideSalesRep = {
    customerComment: null as string | null,
    lusiniOrderIsOutsideSales: false,
    lusiniOrderIsSpecimen: false,
    lusiniOrderIsOffer: false
  }
): t.Response<{ redirectUrl: string; orderNumber: string }> {
  let order: any

  // create order
  try {
    const [result, status] = await fetchFromShopware<CreateOrderResponse>({
      method: 'POST',
      url: '/checkout/order',
      body: outsideSalesRepData
    })

    if (status !== 200) {
      throw new Error('CREATE_ORDER_FAILED')
    }

    order = result
  } catch (e: any) {
    return { status: 500, payload: e }
  }

  return sendOrder(order, paypalOrderId)
}

export async function sendOrder(
  order: {
    orderNumber: string
    id: string
  },
  paypalOrderId = ''
): t.Response<{ redirectUrl: string; orderNumber: string }> {
  let rootUrl = window.location.origin

  if (process.env.NODE_ENV === 'production') {
    rootUrl = window.location.origin + '/' + config.locale
  }

  try {
    for (let i = 0; i < 5; i++) {
      const [result, status] = await fetchFromShopware<HandlePaymentResponse>({
        method: 'POST',
        url: '/handle-payment',
        body: {
          orderId: order.id,
          finishUrl: `${rootUrl}/checkout/success/?order=${order.orderNumber}${
            paypalOrderId ? '&paypalOrderId=' + paypalOrderId : ''
          }`,
          errorUrl: rootUrl + '/checkout/failure/?order=' + order.orderNumber,
          // add paypal shape when paypal express is active
          ...(paypalOrderId
            ? {
                isPayPalExpressCheckout: true,
                paypalOrderId: paypalOrderId
              }
            : {})
        }
      })

      if (status === 200) {
        const toUrl = `${process.env.NODE_ENV === 'development' ? '' : '/' + config.locale}/checkout/overview?order=${order.orderNumber}`
        window.history.replaceState(
          {
            redirectUrl: '/checkout/failure?order=' + order.orderNumber,
            orderNumber: order.orderNumber
          },
          '',
          toUrl
        )
        if (typeof window !== 'undefined')
          localStorage.setItem('orderNumber', order.orderNumber)

        return {
          status: 200,
          payload: {
            redirectUrl:
              result.redirectUrl ||
              rootUrl + '/checkout/success?order=' + order.orderNumber,
            orderNumber: order.orderNumber
          }
        }
      }

      await new Promise((r) => setTimeout(r, 100))
    }

    throw new Error('CREATE_PAYMENT_FAILED')
  } catch (e: any) {
    return { status: 500, payload: e }
  }
}

import React from 'react'
import ArrowRightIcon from 'assets/arrow-button-right.svg'
import { styled } from 'styled-components'
import { theme } from 'containers/GlobalStyle'

type Props = {
  facing: 'left' | 'right' | 'up' | 'down'
  onClick: () => void
  hoverText?: string
  'data-cy-handle'?: string
  'data-cy-state'?: string
}

export default function SliderButton(props: Props) {
  return (
    <Wrapper
      $facing={props.facing}
      className="SliderButton"
      title={props.hoverText}
      onClick={() => props.onClick()}
      data-cy-handle={props['data-cy-handle']}
      data-cy-state={props['data-cy-state']}
    >
      <ArrowWrapper $facing={props.facing}>
        <ArrowRightIcon />
      </ArrowWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.button<{
  $facing: 'left' | 'right' | 'up' | 'down'
}>`
  position: absolute;
  right: ${(props) => props.$facing == 'right' && 0};
  left: ${(props) => props.$facing == 'left' && 0};
  bottom: ${(props) => props.$facing == 'down' && 0};
  top: ${(props) => props.$facing == 'up' && 0};
  cursor: pointer;
  margin: ${theme._spacing.xs}px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 2.5rem;
  width: 2.5rem;
  @media (min-width: ${theme.ms.MD}px) {
    height: 3.125rem;
    width: 3.125rem;
    margin: ${theme._spacing.s}px;
  }
  background: ${theme.colors.white};
  box-shadow:
    0px 1px 8px 0px rgba(0, 0, 0, 0.04),
    0px 3px 4px 0px rgba(0, 0, 0, 0.05),
    0px 3px 3px -2px rgba(0, 0, 0, 0.08);
  border-radius: 0.3125rem;
  outline: solid 1px ${theme.colors.shade.b2};
`

const ArrowWrapper = styled.div<{ $facing: 'left' | 'right' | 'up' | 'down' }>`
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  display: flex;

  transform: ${(props) => props.$facing === 'left' && 'rotate(180deg)'};
  transform: ${(props) => props.$facing === 'up' && 'rotate(-90deg)'};
  transform: ${(props) => props.$facing === 'down' && 'rotate(90deg)'};
  g {
    stroke: ${theme.colors.shade.b2};
  }
`

import * as React from 'react'

type Output<T> = React.RefObject<T | null>

function containsTarget(rectToIgnore: DOMRect, event: React.MouseEvent) {
  const { top, left, bottom, right } = rectToIgnore
  const { clientX, clientY } = event
  return clientX > left && clientX < right && clientY > top && clientY < bottom
}

export default function useOutsideClick<
  T extends HTMLDivElement & HTMLButtonElement
>(handler: (e: React.MouseEvent) => any, rectToIgnore?: DOMRect): Output<T> {
  const ref = React.useRef<T>(null)

  React.useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (rectToIgnore && containsTarget(rectToIgnore, event)) ||
        event.target === document.body.parentElement
      )
        return

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, rectToIgnore])

  return ref
}

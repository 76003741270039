import config from '../config'

export default function priceStr(price: number | string) {
  const priceInt = typeof price === 'string' ? parseFloat(price) : price
  const maximumFractionDigits = priceInt < 0.01 ? 3 : 2
  const locale = config.i18n.locale === 'fr-CH' ? 'de-CH' : config.i18n.locale
  return priceInt.toLocaleString(locale, {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: config.i18n.currency_ISO,
    maximumFractionDigits: maximumFractionDigits
  })
}
